export default (pattern) => {
  const words = pattern
    .trim()
    .replace(/\s+/g, ' ')
    .toLowerCase()
    .split(' ')
    .filter(Boolean);
  return words.length ? (text) => {
    const sample = text.toLowerCase();
    return words.filter((word) => sample.includes(word)).length;
  } : () => 1;
};
