import blockEvent from './blockEvent';

export const checkValidity = (ref, event) => {
  if (event) blockEvent(event);
  return ref?.current?.checkValidity();
};

export const submit = (ref) => {
  setTimeout(() => ref.current.querySelector('[type="submit"]').click(), 50);
  return false;
};
