import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage  } from "gatsby-plugin-image"

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Teamsection = () => {
  const { TeamMembers } = useStaticQuery(graphql`
    query Query {
      TeamMembers : allPrismicTeamMembers {
        edges {
          node {
            data {
              name {
                text
                html
              }
              designation
              linkedin_url
              image {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }      
    }
  `)
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 991 },
      items: 3,

    },
    tablet: {
      breakpoint: { max: 990, min: 768 },
      items: 2,

    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,

    }
  };

  return (
    <>
      <div className="pt-16 about-team overflow-hidden">
        <div className="w-full pb-20">
          <div className="max-w-7xl mx-auto px-6 relative">
            <h6 className="text-lg font-medium text-uppercase text-blue mb-7">THE FACES OF CARRIYO</h6>

            <Carousel responsive={responsive} arrows={true} infinite={true}>
              {TeamMembers && TeamMembers.edges && TeamMembers.edges.map((item, index)=>{
                  return(
                    <div className="mr-5 relative" key={index}>
                      <div className="w-full">
                      <GatsbyImage image={getImage(item.node.data.image)} alt={item.node.data.name.text} className="rounded-tr-2xl rounded-tl-2xl h-96" />
                      </div>
                      
                    <div className="absolute top-5 left-6">
                      <a href={item?.node?.data?.linkedin_url} target="_blank" rel="noopener noreferrer">
                      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_47_9280)">
                          <rect x="10" y="9" width="39.9999" height="39.9999" rx="20" fill="white" />
                        </g>
                        <path d="M23.5634 35.2051V25.1352H26.3562V35.2051H23.5634ZM24.9663 23.8371C24.5511 23.8371 24.1949 23.6994 23.8977 23.4241C23.6049 23.1444 23.4585 22.81 23.4585 22.421C23.4585 22.0364 23.6049 21.7064 23.8977 21.4311C24.1949 21.1513 24.5511 21.0115 24.9663 21.0115C25.3815 21.0115 25.7356 21.1513 26.0284 21.4311C26.3256 21.7064 26.4742 22.0364 26.4742 22.421C26.4742 22.81 26.3256 23.1444 26.0284 23.4241C25.7356 23.6994 25.3815 23.8371 24.9663 23.8371ZM31.3862 29.3834V35.2051H28.5934V25.1352H31.2551V26.9118H31.3731C31.596 26.3262 31.9697 25.8629 32.4942 25.522C33.0187 25.1767 33.6546 25.0041 34.402 25.0041C35.1013 25.0041 35.711 25.157 36.2311 25.463C36.7512 25.7689 37.1555 26.206 37.4439 26.7742C37.7324 27.338 37.8766 28.011 37.8766 28.7934V35.2051H35.0838V29.2916C35.0881 28.6754 34.9308 28.1946 34.6118 27.8493C34.2927 27.4997 33.8534 27.3248 33.294 27.3248C32.9181 27.3248 32.586 27.4057 32.2975 27.5674C32.0134 27.7291 31.7905 27.9651 31.6288 28.2755C31.4715 28.5814 31.3906 28.9507 31.3862 29.3834Z" fill="#00063B" />
                        <defs>
                          <filter id="filter0_d_47_9280" x="0" y="0" width="59.9999" height="59.9999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_47_9280" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_47_9280" result="shape" />
                          </filter>
                        </defs>
                      </svg>
                      </a>
                    </div>
                    <div className="bg-white shadow-lg lg:pt-6 pt-4 lg:pl-9 pl-6 lg:pb-8 pb-4 rounded-br-2xl rounded-bl-2xl">
                      <h4 className="lg:text-3xl text-2xl font-medium text-blue tracking-tight">{item.node.data.name && item.node.data.name.text}</h4>
                      <p className="text-lg font-normal text-blue">{item.node.data.designation && item.node.data.designation}</p>
                    </div>
                  </div>
                  ) 
              })}
            </Carousel> 
          </div>
        </div>
      </div>
    </>
  )
}
export default Teamsection
