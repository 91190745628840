import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Button3 } from "../Elements/Buttons"
import Link from "../Elements/Link"

const Blogsection = ({ slice }) => {
  const { Rectangleleft, Rectangleright, Shipment, ShipmentMobile,LineMobile } = useStaticQuery(
    graphql`
      query {
        Rectangleleft: file(relativePath: {eq: "Rectangleleft.png"}) {
          childImageSharp {
            fixed(width: 480, height:10) {
              srcWebp
              srcSetWebp
            }
          }
        }
        Rectangleright: file(relativePath: {eq: "Rectangleright.png"}) {
          childImageSharp {
            fixed(width: 480, height:10) {
              srcWebp
              srcSetWebp
            }
          }
        }
        LineMobile: file(relativePath: {eq: "line-mobile.png"}) {
          childImageSharp {
            fixed(width: 140, height:4) {
              srcWebp
              srcSetWebp
            }
          }
        }
        Shipment: file(relativePath: {eq: "shipment.png"}) {
          childImageSharp {
            fixed(width: 198, height:208) {
              srcWebp
              srcSetWebp
            }
          }
        }
        ShipmentMobile: file(relativePath: {eq: "shipment-mobile.png"}) {
          childImageSharp {
            fixed(width: 74, height:150) {
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    `
  );
  const { primary, items } = slice
  const { heading } = primary
  return (
    <div className="bg-gradient w-full md:mb-28 mb-14 lg:py-36 py-14 relative text-center">
      <div className="absolute sm:block hidden md:right-8 -bottom-32  sm-right-0 -right-0">
        <img loading="lazy" src={Shipment.childImageSharp.fixed.srcWebp} width="198" height="208" />
      </div>
      <div className="absolute sm:hidden block -bottom-20 -right-0">
        <img loading="lazy" src={ShipmentMobile.childImageSharp.fixed.srcWebp} width="74" height="150" />
      </div>
      <div className="max-w-7xl mx-auto blogttl lg:px-0 px-4">
        <div className="relative overflow-hidden w-full">
          <h2 className="relative z-10 tracking-tighter md:text-center text-left text-white xl:text-7xl sm:text-5xl text-4xl">{heading && heading.text}<span className="relative z-20">&nbsp;</span></h2>
          <div className="md:block line-hidden z-1 absolute xl:-left-8 -left-1 xl:top-9 top-6 xl:max-w-sm max-w-xs">
            <img loading="lazy" src={Rectangleleft.childImageSharp.fixed.srcWebp} width="480" height="10" />
          </div>
          <div className="md:block line-hidden z-1 absolute xl:-right-10 md:-right-3 xl:top-9 top-7 xl:max-w-sm  max-w-xs">
            <img loading="lazy" src={Rectangleright.childImageSharp.fixed.srcWebp} width="480" height="10" />
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-3 grid-flex-col md:gap-5 gap-8 lg:pt-24 pt-14 sm:pb-16 pb-10 ">
          {items && items.filter((item) => item?.blog_posts?.document?.uid).slice(0, 3).map((item, index) => (
            <div className="h-full flex flex-col" key={index}>
              <div className="w-full">
                <Link href={`/blog/${item?.blog_posts?.document?.uid}`}><GatsbyImage image={getImage(item?.blog_posts?.document?.data?.image)} alt={item?.blog_posts?.document?.data?.title?.text} className="rounded-tr-2xl rounded-tl-2xl w-full" /></Link>
              </div>
              <div className="bg-white xl:p-8 p-3 rounded-br-2xl rounded-bl-2xl -mt-4 z-10 relative h-full">
                <h4 className="lg:text-2xl text-xl text-left tracking-tight text-blue font-medium "><Link href={`/blog/${item?.blog_posts?.document?.uid}`} className="text-blue no-underline">{item?.blog_posts?.document?.data?.title?.text}</Link></h4>
              </div>
            </div>
          ))}
        </div>
        <Button3 button_link="/blog">Read More</Button3>
      </div>
    </div>
  )
};

export default Blogsection;

export const query = graphql`
  fragment aboutBlogSectionSlice on PrismicAboutusDataBodyFromTheBlog {
    id
    slice_type
    primary {
      heading {
        text
        html
      }
    }
    items {
      blog_posts {
        document {
          ... on PrismicPosts {
            id
            uid
            data {
              title {
                text
                html
              }
              image {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
