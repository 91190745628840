import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react"

import { components } from "../components/About"
import SEO from "../components/seo"
import Layout from "../components/layout"
const AboutUs = (props) => {
  const { data } = props
  if (!data) return null  
  const pageData = data.prismicAboutus
  const page = pageData.data || {}
  return (
    <Layout theme={props.pageContext.header_theme && props.pageContext.header_theme}>    
      <SEO
         context={props.pageContext}
         cpath={props.location.pathname}
      />
      <SliceZone
        slices={page.body}
        components={components}
        context={pageData}
        defaultComponent={() => null}
      />  
    </Layout>
  );
};

export default AboutUs

export const query = graphql`
  query aboutUsQuery($uid: String!){
    prismicAboutus(uid: {eq: $uid}) {
        uid
        id
        type
        data{
            body{
                ... on PrismicSliceType {
                  slice_type
                }
               ...aboutHeroSlice
               ...aboutBlogSectionSlice
               ...aboutTestimonialSlice
            }
        }
    }
  }
`
