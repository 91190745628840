import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"

const JoiningSection = ({heading, out_of_box_content, image}) => {
    return (
        <div className="w-full lg:pb-40 pb-20">
            <div className="max-w-7xl mx-auto px-4 pb-8">
                <div className="w-full  flex md:items-center md:flex-row flex-col">
                    <div className="lg:w-1/2 joinlink">
                        <h2 className=" md:w-96 w-full lg:text-5xl sm:text-4xl text-3xl  font-medium text-blue tracking-tighter mb-8">{heading && heading.text}</h2>
                        <PrismicRichText field={out_of_box_content.richText}
                        components={{
                            paragraph: ({ children }) => <p className="md:w-4/5 w-full md:text-xl text-lg font-normal text-blue mb-9">{children}</p>,
                        }}
                        />
                    </div>
                    <div className="md:w-1/2 joinimg relative">
                      <div className="w-full">
                        <div className="rounded-2xl joinimgg">
                           <GatsbyImage image={getImage(image)} alt={heading && heading.text} className="rounded-2xl block"  />
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default JoiningSection
