import * as React from "react"
import { graphql } from "gatsby"
import ButtonGetStarted from '../Buttons/ButtonGetStarted'
import ButtonWatchDemo from '../Buttons/ButtonWatchDemo'
import FeaturesList from "../Elements/FeaturesList"
import WhoWeAreSection from "./whowesection"
import JoiningSection from "./joiningsection"
import Teamsection from "./teamsetion"

const HeroSection = ({ slice }) => {
    const { primary, items } = slice
    const { heading,
        sub_heading, 
        who_we_are_heading,
        who_we_are_content,
        who_we_are_image,
        joining_the_team_heading,
        joining_the_team_content,
        joining_the_team_image
     } = primary
    return (
        <div className="background1">
            <div className="md:pt-40 pt-32 lg:pb-32 pb-20 relative ">
                <div className="bg1 absolute top-0 right-0"></div>
                <div className="max-w-4xl mx-auto px-4 ">
                    <div className="md:text-center text-left abt-txt">
                        <h6 className="text-lg md:mb-8 mb-3 font-medium text-blue">ABOUT US</h6>
                        <h1 className="lg:text-7xl md:text-6xl text-3xl md:mb-9 mb-5 text-blue font-medium tracking-tighter">{heading && heading.text}</h1>
                        <p className="md:text-xl text-md md:mx-auto md:mb-10 text-blue font-normal">{sub_heading && sub_heading}</p>
                        <div className="flex gap-2 justify-center items-start md:items-center md:flex-row flex-col">
                            <ButtonGetStarted />
                            <ButtonWatchDemo />
                        </div>
                    </div>
                </div>
                <div className="max-w-4xl mx-auto px-4 md:pt-28 pt-20">
                    <FeaturesList items={items} />
                </div>
            </div>
            <WhoWeAreSection heading={who_we_are_heading} content={who_we_are_content} image={who_we_are_image}/>
            <Teamsection/>
            <JoiningSection heading={joining_the_team_heading} out_of_box_content={joining_the_team_content} image={joining_the_team_image} />
        </div>
    )
}
export default HeroSection

export const query = graphql`
  fragment aboutHeroSlice on PrismicAboutusDataBodyHero {
    id
    slice_type
    primary {
        heading {
            text
            html
        }
        sub_heading
        who_we_are_heading {
            text
            html
        }
        who_we_are_content {
            text
            richText
        }
        who_we_are_image {
            gatsbyImageData(layout: FULL_WIDTH)
        }
        joining_the_team_heading {
            text
            html
        }
        joining_the_team_content {
            text
            richText
            html
        }
        joining_the_team_image {
            gatsbyImageData(layout: FULL_WIDTH)
        }
    }
    items {
    feature_text
    feature_count {
        text
        html
    }
    }
  }
`