import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ButtonGetStarted from "../Buttons/ButtonGetStarted";



const Testimonial = ({ slice }) => {
    const { items } = slice
    const { author, designation, content, image } = items[0].testimonial.document.data
    return (
        <div className="w-full text-center md:mb-32 mb-20">
            <div className="max-w-7xl mx-auto px-4 aboutteam">          
                <div className="text-center">
                <img loading="lazy" src={image.fluid.srcWebp} srcSet={image.fluid.srcSetWebp} alt={author} width={150} height={150} className="mx-auto" />
                    <h6 className="font-bold text-xl text-blue md:pt-6 pt-4 pb-1.5">{author}</h6>
                    <h6 className="text-lg text-blue md:pb-12 pb-6">{designation}</h6>
                    <div className="">
                        <StaticImage src="../../assets/images/lineimg.png"  alt={author} width={120} height={2} className="mx-auto" />
                    </div>
                </div>
                <p className="mx-auto md:pt-10 pt-4 sm:text-2xl text-xl italic font-normal text-blue">“{content}”</p>
                <ButtonGetStarted/>
            </div>
            
        </div>
    )
}

export default Testimonial

export const query = graphql`
  fragment aboutTestimonialSlice on PrismicAboutusDataBodyTestimonials {
    id
    slice_type
    primary {
    heading {
        html
        text
    }
    }
    items {
    testimonial {
        document {
        ... on PrismicTestimonials {
            id
            data {
            author
            content
            designation
            image {
                fluid {
                srcWebp
                srcSetWebp
                }
            }
            }
        }
        }
    }
    }
  }
`
