import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const WhoWeAreSection = ({heading, content, image}) => {
    return (
        <div className="md:py-20 pt-16 pb-4">
            <div className="max-w-7xl mx-auto px-4 ">
                <div className="flex w-full md:px-4  md:flex-row flex-col">
                    <div className="md:w-1/2 w-full bg-gradient md:rounded-bl-2xl md:rounded-tl-2xl md:rounded-t-none rounded-t-2xl relative md:border-r-4 md:border-b-0 border-b-4 border-red-600">
                        <div className="bg3 absolute md:-left-8 md:-top-12 -right-3 -top-14"></div>
                        <div className="spheres absolute md:hidden block -top-12 left-12"></div>
                        <div className="w-full who-cnt lg:pt-40 md:pt-28 sm:pt-16 pt-14 lg:pl-14 md:pl-8 pl-6 lg:pr-16 md:pr-8 pr-6 md:pb-0 mb-5">
                            <h3 className="text-white xl:text-5xl md:text-3xl text-2xl font xl:mb-6 mb-2">{heading && heading.text}</h3>
                            <p className="text-white xl:text-xl lg:text-lg md:text-sm text-base font-normal m-0">{content && content.text}</p>
                        </div>
                    </div>
                    <div className="md:w-1/2 w-full">
                        <GatsbyImage image={getImage(image)} alt={heading && heading.text} className="md:rounded-br-2xl md:rounded-tr-2xl md:rounded-bl-none rounded-b-2xl" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WhoWeAreSection